/* new design common drop down icon - (01/12/2023) */

.ui.dropdown,
.Select-control {
	border-color: var(--fp-secondary-outline) !important;
}

.ui.selection.dropdown {
	min-height: 36px !important;
	padding-right: 3em !important;
	border-radius: 8px !important;
	min-width: 250px !important;
	width: 100%;
	transition: box-shadow 0.1s ease, width 0.1s ease !important;
}

.review-invite-form .ui.selection.dropdown {
	min-height: 36px !important;
	padding-right: 3em !important;
	border-radius: 8px !important;
	min-width: 250px !important;
	width: 100% !important;
	transition: box-shadow 0.1s ease, width 0.1s ease !important;
}


.ui.search.dropdown>input {
	height: 100% !important;
}

.ui.active.selection.dropdown {
	box-shadow: rgba(0, 142, 255, 0.25) 0px 0px 0px 0.2rem !important;
	border-color: rgb(0, 142, 255) !important;
	transition: box-shadow 0.1s ease, width 0.1s ease !important;
}

.ui.search.dropdown.active>input.search,
.ui.search.dropdown.visible>input.search .ui.search.selection.dropdown>input.search {
	line-height: 1 !important;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 100%;
	padding: 12px !important;
	padding-top: 15px !important;
	padding-right: 3em !important;
}

.ui.dropdown>.dropdown.icon:before {
	content: "" !important;
	border: solid #333;
	border-width: 0 1px 1px 0 !important;
	display: block;
	height: 6px;
	width: 6px;
	padding: 3px;
	transform: rotate(45deg) !important;
	-webkit-transform: rotate(45deg);
}

.ui.active.dropdown .menu {
	border: unset !important;
	margin: 0 0 !important;
	margin-top: 1px !important;
}

.ui.active.dropdown {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.ui.dropdown .menu {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.ui.dropdown.select-range.active>.dropdown.icon {
	transform: rotate(180deg) !important;
}

.ui.active.visible.search.selection.dropdown[aria-expanded="true"]>i.icon:before {
	transform: rotate(-135deg) !important;
}


.ui.selection.dropdown>i.icon:before {
	transition: 0.3s ease-in-out;
}

.ui.selection.dropdown>.search.icon,
.ui.selection.dropdown>.delete.icon,
.ui.selection.dropdown>.dropdown.icon {
	margin: 0 !important;
	padding: 0 !important;
	top: 48% !important;
	transform: translateY(-60%) !important;
}

/* Date filter */
.ui.dropdown.select-range {
	min-height: 40px !important;
	padding: 15px !important;
	/* padding-top: 15px !important; */
	padding-right: 2em !important;
	border-radius: 8px !important;
	width: 250px !important;
	transition: box-shadow 0.1s ease, width 0.1s ease !important;
	border: 1px solid rgba(34, 36, 38, 0.15);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ui.dropdown.select-range>span {
	text-overflow: ellipsis;
	text-wrap: nowrap;
	overflow: hidden;
}

.ui.dropdown.select-range>.dropdown.icon {
	position: absolute;
	right: 10px;
	transition: 0.3s ease-in-out;
}

.side-filter .ui.dropdown.select-range>.dropdown.icon {
	position: absolute;
	right: 15px;
	transition: 0.3s ease-in-out;
}

.side-filter .ui.dropdown.select-range {
	min-height: 34.25px !important;
	padding: 15px !important;
	/* padding-top: 15px !important; */
	padding-right: 2em !important;
	border-radius: 4px !important;
	width: 250px !important;
	transition: box-shadow 0.1s ease, width 0.1s ease !important;
	border: 1px solid rgba(34, 36, 38, 0.15);
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.date-select .ui.dropdown .menu {
	max-height: 250px !important;
	padding: 10px;
}
.date-select.list-date-filter .ui.dropdown .menu {
	max-height: 250px !important;
	padding: 10px;
}

/* .ui.dropdown:hover{
	border-color: rgba(34, 36, 38, 0.35);
    box-shadow: none;
} */



.ui.search.dropdown>.text {
	top: 1px;
}



/* DATE select-box */
.fp-date-picker-wrapper {
	position: relative;
}

.fp-date-picker-wrapper input.fp-date-picker {
	border: 1px solid #BFC2CB !important;
	border-radius: 8px !important;
}

.fp-date-picker-wrapper .date-icon {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
}

input.schedule-later-input {
	border-radius: 8px;
	padding: 15px 10px;
	width: 300px;
	border: 1px solid #BFC2CB;
}


/* React-select */

.Select.select__component {
	min-height: 40px;
}

.Select-control {
	border-radius: 8px !important;
	height: 100% !important;
}

.Select.height-48 .Select-control,
.Select.height-48 .Select-multi-value-wrapper,
.Select.height-48 .Select-input,
.Select.height-48 .Select-input input,
.Select.height-48 .react-select-2--value {
	height: 48px;
}

.Select .Select-control:focus-within {
	box-shadow: rgba(0, 142, 255, 0.25) 0px 0px 0px 0.2rem !important;
	border-color: rgb(0, 142, 255) !important;
}

.Select.height-48 .Select-input input {
	padding: 0 !important;
}

.Select-menu-outer {
	border: unset !important;
	opacity: 1;
	transition: opacity 229ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	background-color: #fff;
}

.ui.form .fields.error .field .ui.dropdown .menu .selected.item,
.ui.form .field.error .ui.dropdown .menu .selected.item {
	background-color: #fff !important;
}

.ui.form .fields.error .field .ui.dropdown,
.ui.form .fields.error .field .ui.dropdown .item,
.ui.form .field.error .ui.dropdown,
.ui.form .field.error .ui.dropdown .text,
.ui.form .field.error .ui.dropdown .item {
	background-color: #fff !important;
	color: var(--fp-secondary-font-dark);
	line-height: 18px;
}